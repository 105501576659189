import React, { useEffect, useState } from "react";
import { history } from "../managers/history";

const DeActivatedScreen = ({ margin }) => {
  const [componentHeight, setComponentHeight] = useState(window.innerHeight);
  const currentYear = new Date().getFullYear();

  const updateComponentHeight = () => {
    setComponentHeight(window.innerHeight);
  };

  useEffect(() => {
    updateComponentHeight();
    window.addEventListener("resize", updateComponentHeight);
    return () => {
      window.removeEventListener("resize", updateComponentHeight);
    };
  }, []);

  return (
    <div
      style={{ height: componentHeight }}
      className="bg-white flex  flex-col justify-between items-center p-15"
    >
      <div onClick={() => history.push("/")}>
        <img src="/images/zbrain-logo.svg" alt="" />
      </div>
      <div
        className={`h-233px lg:w-415px text-center border border-grey-DDDFDE ${margin}  rounded-xl flex items-center  flex-col p-5`}
      >
        <img src="/images/alert.svg" alt="" className="w-7.75 h-7 mt-5" />
        <h1 className="mt-4 font-PoppinsMedium text-black-0 md:text-ft30 text-ft7">
          App Not Available
        </h1>
        <span className="mt-3.5 font-PoppinsRegular md:text-ft4 text-ft3 text-black-10">
          The link to this app appears to be deleted or deactivated. Please contact your ZBrain
          Admin.
        </span>
      </div>
      <div className="mt-auto">
        <span className="font-PoppinsRegular text-ft2 text-grey-B4B4B4">
          © {currentYear} ZBrain.ai | All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default DeActivatedScreen;
