import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST },
  API_END_POINT: { AGENT_EXECUTE },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export function getHeaderForUploadFile() {
  return {
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class AgentExecutorService {
  baseUrl = process.env.REACT_APP_WEB_AGENT_MICROSERVICE_URL;

  executeAgents = async (tenantId, data) => {
    const query = `?tenantId=${tenantId}`;
    const url = this.baseUrl + AGENT_EXECUTE + query;
    return new Promise((resolve, reject) => {
      httpService(POST, url, data, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
