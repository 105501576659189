
export const SET_REMAINING_CREDITS = 'SET_REMAINING_CREDITS';

export const setRemainingCredits = (credits) => ({
  type: SET_REMAINING_CREDITS,
  payload: credits,
});

const initialState = {
  remainingCredits: 0,
};

const creditsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REMAINING_CREDITS:
      return {
        ...state,
        remainingCredits: action.payload,
      };
    default:
      return state;
  }
};

export default creditsReducer;

