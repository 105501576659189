import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import utility from "../utility";

const { FLOW_TOKEN, AUTH0_ID_TOKEN } = keyTypeConstants;
const {
  METHOD_TYPE: { POST },
  API_END_POINT: { AUTH_TOKEN, APP_CONNECTIONS },
} = httpConstants;

export default class ZBrainFlowService {
  baseUrl = process.env.REACT_APP_TOOL_MICROSERVICE;

  async getHeaders() {
    const tenant = utility.getDataFromLocalStorage("user");

    if (!tenant) {
      throw new Error("ZbrainFlowService: Tenant not found");
    }
    const token = await this.getToken({
      tenantId: tenant.tenantId,
    });
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    };
  }

  getToken = async (data) => {
    try {
      const token = sessionManager.getDataFromCookies(FLOW_TOKEN);

      if (token) return token;

      const platformToken = sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

      const response = await fetch(this.baseUrl + `${AUTH_TOKEN}`, {
        method: POST,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${platformToken}`,
        },
        body: JSON.stringify({
          ...data,
          token: platformToken,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();

      sessionManager.setDataInCookies(res, FLOW_TOKEN, 1);
      return res;
    } catch (error) {
      console.error("Error fetching authToken:", error);
      throw error;
    }
  };

  getConnections = async (data) => {
    const query = new URLSearchParams(data).toString();
    const headers = await this.getHeaders();

    try {
      const response = await fetch(
        `${this.baseUrl}${APP_CONNECTIONS}?${query}`,
        { method: "GET", headers }
      );
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error fetching tool connections:", error);
      throw error;
    }
  };

  deleteConnection = async (connectionId) => {
    const headers = await this.getHeaders();

    try {
      const response = await fetch(
        this.baseUrl + `${APP_CONNECTIONS}/${connectionId}`,
        { method: "DELETE", headers }
      );
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error fetching tool connections:", error);
      throw error;
    }
  };

  addConnection = async (data) => {
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${APP_CONNECTIONS}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };
}
