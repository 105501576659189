import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import { history } from "../managers/history";

const { AUTH0_ID_TOKEN, CRM_CODE } = keyTypeConstants;

const {
  METHOD_TYPE: { POST, PATCH },
  API_END_POINT: { CREATE_PIPEDRIVE_TENANT, UPDATE_PIPEDRIVE },
} = httpConstants;

export default class PipeDriveService {
  baseUrl = process.env.REACT_APP_CONTENT_MICROSERVICE_URL;

  _getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionManager.getDataFromCookies(
        AUTH0_ID_TOKEN
      )}`,
    };
  }

  createTenant = async (data) => {
    return new Promise((resolve, reject) => {
      httpService(POST, `${this.baseUrl}${CREATE_PIPEDRIVE_TENANT}`, data)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateConfig = async (data) => {
    const url = `${this.baseUrl}${UPDATE_PIPEDRIVE}`;
    const res = await httpService(PATCH, url, data, this._getHeaders());
    if (!res.success || res.responseCode !== 200) throw res;
    return res?.responseData;
  };

  pipedriveTenant = async (_code, _tenantId) => {
    try {
      const response = await this.createTenant({
        code: _code,
        tenantId: _tenantId,
      });
      localStorage.setItem("PIPEDRIVE_USER", JSON.stringify(response.pipedriveDetails));
      if (!response?.isSubscribed) {
        history.push("/pipedrive/pricing");
      } else {
        history.push("/sales-enablement-tool");
      }
      return true;
    } catch (error) {
      console.log(error);
    } finally {
      sessionManager.removeDataFromCookies(CRM_CODE);
    }
    return false;
  };
}
