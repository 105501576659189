import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const { AUTH0_ID_TOKEN } = keyTypeConstants;
const {
  METHOD_TYPE: { GET, POST, DELETE, PUT },
  API_END_POINT: {
    WORKERS,
    WORKER_TEMPLETE,
    WORKER_RECORDS,
    WORKER_TEMPLETES,
    TRIGGER_WORKER,
    WORKERS_UPLOAD,
    WORKER_REPORTS,
    WORKER_TEMPLATE_CATEGORIES,
    WORKER_RECORDS_LOGS,
    RETRY_FAILED_ACTIVITY,
    WORKER_FEEDBACK,
    HANDLE_OUTPUT_ACTION,
  },
} = httpConstants;

export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class WorkerService {
  baseUrl = process.env.REACT_APP_WORKER_MICROSERVICE;
  localUrl = "http://localhost:3012";

  getWorkersTemplates = async (data) => {
    const query = new URLSearchParams(data).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        `${this.baseUrl}${WORKER_TEMPLETES}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getWorkers = async (requestData) => {
    const query = new URLSearchParams(requestData).toString();
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + `${WORKERS}?${query}`, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deleteWorker = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `${WORKERS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getWorkerRecords = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKERS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  deployWorker = async (data) => {
    const result = await httpService(
      POST,
      `${this.baseUrl}${WORKERS}`,
      data,
      getHeaders()
    );

    if (!result.success || result.responseCode !== 200) throw result;
    return result.responseData;
  };

  triggerWorker = async (data) => {
    const result = await httpService(
      POST,
      `${this.baseUrl}${TRIGGER_WORKER}`,
      data,
      getHeaders()
    );

    if (!result.success || result.responseCode !== 200) throw result;
    return result.responseData;
  };

  uploadFile = async (form, data) => {
    const query = new URLSearchParams(data).toString();
    const result = await fetch(`${this.baseUrl}${WORKERS_UPLOAD}?${query}`, {
      method: POST,
      headers: {
        Authorization: `Bearer ${sessionManager.getDataFromCookies(
          AUTH0_ID_TOKEN
        )}`,
      },
      body: form,
    }).then((r) => r.json());

    if (!result.success || result.responseCode !== 200) throw result;
    return result.responseData;
  };

  getReports = async (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKER_REPORTS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getworkerTemplateCategories = (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKER_TEMPLATE_CATEGORIES}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getworkerTemplateDetails = (queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKER_TEMPLETE}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateWorkerStatus = async (workerId, data) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        `${this.baseUrl}${WORKERS}/${workerId}`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getRecordDetails = (id, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKER_RECORDS}/${id}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateWorker = async (workerId, data) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        `${this.baseUrl}${WORKERS}/${workerId}`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  updateFeedback = async (workerId, data) => {
    return new Promise((resolve, reject) => {
      httpService(
        PUT,
        `${this.baseUrl}${WORKER_FEEDBACK}/${workerId}`,
        data,
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  getWorkerRecordLogs = (workerRecordId, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        GET,
        this.baseUrl + `${WORKER_RECORDS_LOGS}/${workerRecordId}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };


  retryFailedActivity = async (data) => {
    const result = await httpService(
      POST,
      `${this.baseUrl}${RETRY_FAILED_ACTIVITY}`,
      data,
      getHeaders()
    );

    if (!result.success || result.responseCode !== 200) throw result;
    return result.responseData;
  };

  handleOutputAction = async (data) => {
    const result = await httpService(
      POST,
      `${this.baseUrl}${HANDLE_OUTPUT_ACTION}`,
      data,
      getHeaders()
    );

    if (!result.success || result.responseCode !== 200) throw result;
    return result.responseData;
  };
}
